.up_card {
  animation: up_card 700ms linear both;
}

.down_card {
  animation: down_card 700ms linear both;
}

@keyframes up_card {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes down_card {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

@media screen and (max-width: 440px) {
  @keyframes up_card {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-50px);
    }
  }

  @keyframes down_card {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(50px);
    }
  }
}
